import axios from 'axios'
import { useCallback, useState } from 'react'

import { apiURL } from '../config'

function usePodcasts() {
  const [podcasts, setPodcasts] = useState([])
  const [loading, setLoading] = useState(false)

  const fetchPodcasts = useCallback(async (isLoading = true) => {
    setLoading(isLoading)
    const response = await axios.get(apiURL('/api/projects'), {
      withCredentials: true,
    })
    setLoading(false)
    const { status, data } = response
    if (status === 200) {
      // create ref for each podcast
      // refer: https://stackoverflow.com/a/54633947/13327018
      setPodcasts(data)
    }
  }, [])

  return [loading, podcasts, fetchPodcasts]
}

export default usePodcasts
