import { isDev } from '../env'

// Constants
export const MIN_SATS = 10

// Sources
export const apiURL = (path = '') =>
  (isDev() ? 'https://test.thebiz.pro' : 'https://api.thebiz.pro') + path

export const WS_URL = isDev()
  ? 'wss://ws-test.thebiz.pro/'
  : 'wss://ws.thebiz.pro/'
export const SITE_URL = isDev()
  ? 'https://staging.thebiz.pro'
  : 'https://thebiz.pro'
