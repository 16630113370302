import _isEmpty from 'lodash/isEmpty'
import React, { useState, useEffect } from 'react'
import { toast, ToastContainer } from 'react-toastify'

import usePodcasts from '../hooks/usePodcasts'
import ws from '../ws'

const AppContext = React.createContext({})

export const AppProvider = ({ children }) => {
  const [lightningAddress, setLightningAddress] = useState('')
  const [bitcoinAddress, setBitcoinAddress] = useState('')
  const [blocked, setBlocked] = useState(false)
  const [invoicePaid, setInvoicePaid] = useState(false)
  const [loading, podcasts, fetchPodcasts] = usePodcasts()

  useEffect(() => {
    fetchPodcasts()
  }, [fetchPodcasts])

  useEffect(() => {
    ws.getInstance(socket => {
      // eslint-disable-next-line no-param-reassign
      socket.onmessage = e => {
        try {
          const data = JSON.parse(e.data || '{}')
          if (data.method === 'update') {
            toast.success('Payment received: More audio has been unlocked.')
            // if set to true, switch back to audio-player view from payment view
            setInvoicePaid(true)
            // reset after sometime
            setTimeout(() => {
              setInvoicePaid(false)
            }, 1500)

            // fetch latest podcasts
            fetchPodcasts(false)
          } else if (data.result.blocked) {
            setBlocked(true)
          } else if (!_isEmpty(data.result)) {
            setLightningAddress(data.result.payment_request || '')
            setBitcoinAddress(data.result.address || '')
          }
        } catch (ex) {
          console.warn('wrong JSON string', ex.data)
        }
      }
    })

    return () => ws.disconnect()
  }, [fetchPodcasts])

  return (
    <>
      <AppContext.Provider
        value={{
          lightningAddress,
          setLightningAddress,
          bitcoinAddress,
          setBitcoinAddress,
          blocked,
          setBlocked,
          invoicePaid,
          setInvoicePaid,
          loading,
          podcasts,
        }}
      >
        {children}
      </AppContext.Provider>
      <ToastContainer position='bottom-right' />
    </>
  )
}

export default AppContext
