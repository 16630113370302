import _isFunction from 'lodash/isFunction'

import { WS_URL } from './config'

const MAX_CONNECTION_TRIAL = 5
const KEEP_ALIVE_INTERVAL = 7500
const WS_READY_STATE = {
  CONNECTING: 0,
  OPEN: 1,
  CLOSING: 2,
  CLOSED: 3,
}

// `readyState`: 0: CONNECTING; 1: OPEN; 2: CLOSING; 3: CLOSED
// @see https://developer.mozilla.org/en-US/docs/Web/API/WebSocket/readyState
let ws = { readyState: WS_READY_STATE.CLOSED }
let interval = null

const isDisconnected = () => ws.readyState === WS_READY_STATE.CLOSED
const isConnecting = () => ws.readyState === WS_READY_STATE.CONNECTING

const disconnect = () => {
  if (ws) {
    ws.close()
  }
}

const tryConnect = (tryTime, onOpen) => {
  let tempTryTime = tryTime
  ws = new WebSocket(WS_URL)

  ws.onerror = () => {
    if (tryTime > 0) {
      console.debug('reconnecting ...')
      ws = { readyState: WS_READY_STATE.CLOSED }
      tempTryTime -= 1
      tryConnect(tempTryTime, onOpen)
    }
  }

  ws.onclose = () => {
    console.debug('ws closed')
  }

  ws.onopen = () => {
    console.debug('ws connected')
    if (interval) {
      clearInterval(interval)
      interval = null
    }
    interval = setInterval(() => {
      ws.send(
        JSON.stringify({
          alive: true,
        })
      )
    }, KEEP_ALIVE_INTERVAL)
    if (_isFunction(onOpen)) {
      onOpen(ws)
    }
  }
}

const connect = onOpen => {
  tryConnect(MAX_CONNECTION_TRIAL, onOpen)
}

const getInstance = callback => {
  if (isDisconnected()) {
    connect(callback)
  } else {
    callback(ws)
  }
}

export default {
  getInstance,
  disconnect,
  connect,
  isConnecting,
}
